import React, { useState } from 'react'
import Select from 'react-select';
import industries from '../constants/industries'
import customerindustries from '../constants/customerindustries'
import sellerindustries from '../constants/sellerindustries'
import size from '../constants/cosize'
import customerjobs from "../constants/customerjobs"
import sellerjobs from "../constants/sellerjobs"
import axios from "axios";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage }  from "formik";


//define Customer API URL call
// let baseurl = 'http://scsitev1apis-env.eba-hnxtukew.us-east-2.elasticbeanstalk.com'
// let baseurl = 'https://validation.stickchart.dev'
//let baseurl = 'http://192.168.1.159:8081'
let baseurl = process.env.GATSBY_API_TARGET
let findsessionurl = baseurl + '/api/surveydetails/';
let access_token = process.env.GATSBY_API_KEY

if (process.env.GATSBY_ACTIVE_ENV === 'development') {
  console.log("***LOG RESPONSE*** GATSBY_API_KEY: " + process.env.GATSBY_API_KEY)
  console.log("***LOG RESPONSE*** API_KEY: " + process.env.API_KEY)
  console.log("***LOG RESPONSE*** NODE_ENV: " + process.env.NODE_ENV)
  console.log("***LOG RESPONSE*** ACTIVE_ENV: " + process.env.GATSBY_ACTIVE_ENV)
}

const UserTypeModal = props => {

    
    const [selectedIndustry, setSelectedIndustry] = useState("");
    const [selectedCoSize, setSelectedCoSize] = useState("");
    const [selectedJobTitle, setSelectedJobTitle] = useState("");

    //This handles the different validation requirements between Seller & Customer
    let testSchema

    if (props.usertype==='seller'){
        testSchema = Yup.object().shape({
            industry: Yup.string().required("Please Select an Industry"),
            cosize: Yup.string().required("Please Select Your Company's Size"),
            jobtitle: Yup.string().required("Please Select Your Job Title"),
            noreps: Yup.number().positive().integer().min(1, "Please enter a number greater than 0").required("Please Enter the # of Sales Reps in Your Organization")
        });
    }else{
        testSchema = Yup.object().shape({
            industry: Yup.string().required("Please Select an Industry"),
            cosize: Yup.string().required("Please Select Your Company's Size"),
            jobtitle: Yup.string().required("Please Select Your Job Title"),
            noreps: Yup.number().positive("Please enter a number greater than 0")
        });
    }

    const initialValues = {
        industry: "",
        cosize: "",
        jobtitle: "",
        noreps: ""
    };

    const handleIndustryChange = selectedIndustry => {
        setSelectedIndustry(selectedIndustry);
    };

    const handleCoSizeChange = selectedCoSize => {
        setSelectedCoSize(selectedCoSize);
    };

    const handleJobTitleChange = selectedJobTitle => {
        setSelectedJobTitle(selectedJobTitle);
    };



    const divStyle = { 
          display: props.displayModal ? 'block' : 'none'
     };
     function closeModal(e) {
        props.closeModal()

     }

     function handleKeyDown(e) {
        if (e.keyCode === 27) {
            closeModal()
          }
     }

    function handleDismiss(e) {
        //Dismiss the modal and set to never pop-up again
        typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_main_survey', 'dismissed');
        // Do we capture this in the backend?      
    }

    function onWheel(e) {
        e.stopPropagation();
    }

    return (
        // load the modal background first
        <div aria-hidden="true" className="modal" onClick={ closeModal } onKeyDown={handleKeyDown} style={divStyle} >
            {/* load modal window itself */}
            <div aria-hidden="true" className="modal-content" onClick={ e => e.stopPropagation() } onKeyDown={ e => e.stopPropagation() }>
                <div className="modal-flex">
                    <div class="px-4">
                        <span aria-hidden="true" className="close" onClick={ closeModal } onKeyDown={handleKeyDown} >&times;</span>
                    </div>

                    {/* Modal Heading */}
                    {/* <h1 class={`block w-full text-center text-grey-darkest my-4 ${props.usertype === 'customer' && 'my-8'}`}>
                        Please take a moment to tell us a bit about yourself and your organization...
                    </h1> */}
                    <div class="px-2 w-full mx-auto text-center">
                        <h4>Please take a moment to tell us a bit about yourself and your organization...</h4> {/* formatting specified in global.css - modal-flex h3 */}
                    </div>
                    {/* Initiate Form */}
                    <Formik
                        class="mb-4 md:flex md:flex-wrap md:justify-between"
                        initialValues={initialValues} 
                        validationSchema={testSchema} 
                        onSubmit={(values, { setSubmitting }) => {

                            
                            setTimeout(() => {                                
                                //Get the SessionID - used to commit data to API
                                const sessionID = typeof window !== 'undefined' && window.localStorage.getItem('stickchart:_sc_id');;
                                
                                //Writing values to localStorage
                                //Survey complete, will prevent pop-up in the future
                                typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_main_survey', true);
                                //Sets Usertype survey completed
                                typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_main_survey_usertype', props.usertype);
                                //Sets the Industry response in LocalStorage
                                typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_main_survey_industry', values.industry);
                                //Sets the Company Size response in LocalStorage
                                typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_main_survey_cosize', values.cosize);
                                //Sets the Job Title response in LocalStorage
                                typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_main_survey_jobtitle', values.jobtitle);
                                //Sets the No of Sales Reps response in LocalStorage - Only if usertype is Seller
                                if(props.usertype==='seller'){
                                    typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_main_survey_noreps', values.noreps);
                                }
                                //Sets the No of Sales Reps response in LocalStorage - Only if usertype is Customer, checking to see if any data was entered
                                if(props.usertype==='customer'){
                                    if (values.noreps !== null){
                                        typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_main_survey_noreps', values.noreps);
                                    }
                                }

                                //API call to commit data to backend
                                axios.post(findsessionurl, {
                                    session_id: sessionID,
                                    user_type: props.usertype,
                                    interactors_count: values.noreps,
                                    company_employees: values.cosize,
                                    company_sales_org: values.noreps,
                                    industry_code: values.industry,
                                    job_code: values.jobtitle,
                                }, {
                                    headers: {
                                        // Pass api key in via auth header
                                        'Authorization': 'Api-Key ' + access_token
                                    }
                                })
                                .then((response) => {
                                    // console.log("**Registration - Add ReferredUser via API success** " + response);
                                    if (process.env.GATSBY_ACTIVE_ENV === 'development') {
                                        console.log("***LOG RESPONSE*** **Axios Response** " + JSON.stringify(response));
                                    }
                                }, (error) => {
                                    // console.log("**Registration - Add ReferredUser via API ERROR** " + error)
                                    if (process.env.GATSBY_ACTIVE_ENV === 'development') {
                                        console.log("***LOG RESPONSE*** **Axios Error** " + JSON.stringify(error));
                                    }
                                })
                                //Close the modal window
                                closeModal()

                                setSubmitting(false);
                            }, 400);
                            
                        }}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            setFieldTouched
                        }) => {
                            return (
                                <Form>
                                    <div className="form-group">
                                        {/* 1st Row */}
                                        <div class="flex flex-col mb-4 md:w-full">
                                            <label class="mb-2 capitalize font-semibold text-sm text-grey-darkest" for="cosize">Please select your company size</label>
                                            <Select
                                                class="mt-1"
                                                placeholder="Company Size"
                                                value={selectedCoSize}
                                                onChange={selectedOption => {
                                                    handleCoSizeChange(selectedOption);
                                                    handleChange("cosize")(selectedOption.code);
                                                }}
                                                options={size} 
                                                name="cosize"
                                                id="cosize"
                                                isSearchable={false}
                                                isLoading={false}
                                                loadingMessage={() => "Fetching Company Size Options"}
                                                noOptionsMessage={() => "Company Size appears here"}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                menuPortalTarget={typeof window !== 'undefined' && document.body}
                                            />
                                            {touched.cosize && errors.cosize ? (
                                            <div class="text-xs text-red-500 mt-1">{errors.cosize}</div>
                                            ) : null}
                                        </div>
                                        {/* Customer Specific Sections - hides this section if usertype = seller */}
                                        <div class={`flex-col mb-4 md:w-full ${props.usertype === 'seller' && 'hidden'}`}>
                                            {/* 2nd Row */}
                                            <div class="flex flex-col mb-4 md:w-full">
                                                <label class="mb-2 capitalize font-semibold text-sm text-grey-darkest" for="custjobs">Please select your job title</label>
                                                <Select 
                                                    class="mt-1"
                                                    placeholder="Job Title"
                                                    value={selectedJobTitle}
                                                    onChange={selectedOption => {
                                                        handleJobTitleChange(selectedOption);
                                                        handleChange("jobtitle")(selectedOption.code);
                                                    }}
                                                    options={customerjobs} 
                                                    name="custjobs" 
                                                    id="custjobs" 
                                                    isSearchable={false}
                                                    isLoading={false}
                                                    loadingMessage={() => "Fetching Job Titles"}
                                                    noOptionsMessage={() => "Job Titles appears here"}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    menuPortalTarget={typeof window !== 'undefined' && document.body}
                                                />
                                                {touched.jobtitle && errors.jobtitle ? (
                                                <div class="text-xs text-red-500 mt-1">{errors.jobtitle}</div>
                                                ) : null}
                                            </div>
                                            {/* 3rd Row */}
                                            <div class="flex flex-col mb-4 md:w-full">
                                                <label class="mb-2 capitalize font-semibold text-sm text-grey-darkest" for="industry">Please select your industry</label>
                                                <Select
                                                    placeholder="Industry"
                                                    value={selectedIndustry}
                                                    onChange={selectedOption => {
                                                        handleIndustryChange(selectedOption);
                                                        handleChange("industry")(selectedOption.code);
                                                    }}
                                                    options={customerindustries}
                                                    name="custindustry" 
                                                    id="custindustry"
                                                    isSearchable={false}
                                                    isLoading={false}
                                                    loadingMessage={() => "Fetching Industries"}
                                                    noOptionsMessage={() => "Industry appears here"}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    menuPortalTarget={typeof window !== 'undefined' && document.body}
                                                />
                                                {touched.industry && errors.industry ? (
                                                <div class="text-xs text-red-500 mt-1">{errors.industry}</div>
                                                ) : null}
                                            </div>
                                            {/* Disabled the field unless usertype is Customer */}
                                            {/* 4th Row */}
                                            {props.usertype === "customer" && (
                                                <div class="flex flex-col mb-4 md:w-full">
                                                    <label class="capitalize font-semibold text-sm text-gray-600" htmlFor="eMailPrimary"># of Sales Reps you regularly interact with</label>
                                                    <Field name="noreps" type="number" class={'modal-form-control' + (errors.noreps && touched.noreps ? ' modal-form-control-error' : '')} />
                                                    <ErrorMessage name="noreps" component="div" class="text-xs text-red-500 mt-1" />
                                                </div>
                                            )}
                                        </div>
                                        {/* Seller Specific Sections - hides this section if usertype = customer */}
                                        <div class={`flex-col mb-4 md:w-full ${props.usertype === 'customer' && 'hidden'}`}>
                                            {/* 2nd Row */}
                                            <div class="flex flex-col mb-4 md:w-full">
                                                <label class="mb-2 capitalize font-semibold text-sm text-grey-darkest" for="selljobs">Please select your job title</label>
                                                <Select 
                                                    class="mt-4"
                                                    placeholder="Job Title"
                                                    value={selectedJobTitle}
                                                    onChange={selectedOption => {
                                                        handleJobTitleChange(selectedOption);
                                                        handleChange("jobtitle")(selectedOption.code);
                                                    }}
                                                    options={sellerjobs} 
                                                    name="selljobs" 
                                                    id="selljobs" 
                                                    isSearchable={false}
                                                    isLoading={false}
                                                    loadingMessage={() => "Fetching Job Titles"}
                                                    noOptionsMessage={() => "Job Titles appears here"}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    menuPortalTarget={typeof window !== 'undefined' && document.body}
                                                />
                                                {touched.jobtitle && errors.jobtitle ? (
                                                <div class="text-xs text-red-500 mt-1">{errors.jobtitle}</div>
                                                ) : null}
                                            </div>
                                            {/* 3rd Row */}
                                            <div class="flex flex-col mb-4 md:w-full">
                                                <label class="mb-2 capitalize font-semibold text-sm text-grey-darkest" for="industry">Please select your industry</label>
                                                <Select
                                                    placeholder="Industry"
                                                    value={selectedIndustry}
                                                    onChange={selectedOption => {
                                                        handleIndustryChange(selectedOption);
                                                        handleChange("industry")(selectedOption.code);
                                                    }}
                                                    options={sellerindustries}
                                                    name="sellindustry" 
                                                    id="sellindustry"
                                                    isSearchable={false}
                                                    isLoading={false}
                                                    loadingMessage={() => "Fetching Industries"}
                                                    noOptionsMessage={() => "Industry appears here"}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    menuPortalTarget={typeof window !== 'undefined' && document.body}
                                                />
                                                {touched.industry && errors.industry ? (
                                                <div class="text-xs text-red-500 mt-1">{errors.industry}</div>
                                                ) : null}
                                            </div>
                                            {/* Disabled the field unless usertype is Seller */}
                                            {/* 4th Row */}
                                            {props.usertype === "seller" && (
                                                <div class="flex flex-col mb-4 md:w-full">
                                                    {/* <label class="mb-2 capitalize font-semibold text-sm text-grey-darkest" for="noreps">
                                                        # of Sales Reps in Your Organization:
                                                    </label>
                                                    <Field class="border py-2 px-3 text-grey-darkest text-base" name="noreps" type="number" />
                                                    <div class="text-xs text-red-500 mt-1">
                                                        <ErrorMessage name="noreps" />
                                                    </div> */}
                                                    <label class="capitalize font-semibold text-sm text-gray-600" htmlFor="eMailPrimary"># of Sales Reps in Your Organization:</label>
                                                    <Field name="noreps" type="number" min="0" class={'modal-form-control' + (errors.noreps && touched.noreps ? ' modal-form-control-error' : '')} />
                                                    <ErrorMessage name="noreps" component="div" class="text-xs text-red-500 mt-1" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* When clicked, this will call two functions - 1) handleDimiss to update localStorage so the user is not prompted again and 2) closeModal, to close the modal */}
                                    <div class="pb-4">
                                        <button id="btn-generalregmodalskip-id" onClick={(e) => { handleDismiss(e); closeModal(e)}} class="block pt-2 w-full text-center no-underline text-sm text-grey-dark hover:text-grey-darker">Please Don't Ask Me Again</button>
                                    </div>
                                    <button id="btn-generalregmodalsubmit-id" aria-label="submit-registration" type="submit" class="submit-btn">Submit</button>
                                </Form>
                            );
                        }}
                        
                    </Formik>
                </div>
            </div>
        </div>
    );

}

export default UserTypeModal




