import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import ReactMarkdown from 'react-markdown'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, A11y, Pagination } from 'swiper';
// Import of Swiper styles
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css'


SwiperCore.use([Autoplay, A11y, Pagination ]);


const BWCarousel = ({features,usertype}) => {
    return (
        
            <div class="grid grid-cols-1">
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    loop={true}
                    pagination={
                        { 
                            clickable: false,
                            type: 'bullets',
                            }
                        
                    }
                    autoplay={
                        { 
                            delay: 7000,
                            disableOnInteraction: false
                        }
                        
                    }
                    breakpoints={
                        {
                            // For width > 640 px
                            640: {
                                delay: 7000,
                                slidesPerView: 2,
                                spaceBetween: 30,
                                centeredSlides: true,
                            },
                            // For width > 960 px
                            1080: {
                                delay: 7000,
                                slidesPerView: 3,
                                spaceBetween: 30,
                                centeredSlides: true,
                            },
                            // For width > 1200 px
                            1710: {
                                delay: 7000,
                                slidesPerView: 4,
                                spaceBetween: 30,
                                centeredSlides: true,
                                pagination: false,
                            }                            
                        }  
                    }
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                >
                    {features.map((feature,index)=>{
                        return (
                            <SwiperSlide>
                                <article class="text-gray-600 bg-gray-50 py-2 px-6 mb-8 rounded-lg shadow-md card h-100">
                                    <div class="w-full grid grid-flow-row">
                                        {/* 1st Row */}
                                        <div class="flex items-center px-14">
                                            <Image fluid={feature.image.childImageSharp.fluid} className="w-full 2xl:w-10/12 scxl:w-ful" fadeIn={false} loading="eager" alt={feature.altText}/>
                                        </div>
                                        {/* 2nd Row */}
                                        <div class="flex items-start -mt-6 scmd:-mt-14">
                                            <h4>
                                                {feature.heading}
                                            </h4>
                                        </div>
                                        {/* 3rd Row */}
                                        <div class="flex items-start">
                                            <p>
                                                <ReactMarkdown source={feature.body} />
                                            </p>
                                        </div>
                                    </div>
                                </article>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
    )
}

export default BWCarousel
