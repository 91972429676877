const sellerjobs = [
    {
        value: 'acctexec',
        label: 'Account Executive',
        code: '1004',
    },
    {
        value: 'acctmgr',
        label: 'Account Manager',
        code: '1002',
    },
    {
        value: 'bdmgr',
        label: 'Business Development Manager',
        code: '1005',
    },
    {
        value: 'isr',
        label: 'Inside Sales Rep',
        code: '1001',
    },
    {
        value: 'osr',
        label: 'Oustide Sales Rep',
        code: '1006',
    },
    {
        value: 'salesrep',
        label: 'Sales Rep',
        code: '1003',
    },
    {
        value: 'other',
        label: 'Other',
        code: '2999',
    },
]

export default sellerjobs;