import React, {useState} from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { Link, navigate  } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import UserTypeModal from '../components/usertypemodal'

const query = graphql`
{
    allStrapiMainUserTypes(sort: {fields: rank, order: ASC}) {
      nodes {
        altText
        Heading
        Link
        subheading
        Image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 64) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const LandingUserTypes = () => {
    const data = useStaticQuery(query);
    const {
      allStrapiMainUserTypes:{nodes:userTypes},
    } = data

    const [modalIsOpen,setModalIsOpen] = useState(false);
    const [type,setType] = useState('');


    const setModalIsOpenToTrue =({clickto})=>{
        
        //call function to set the type state (usertype) for proper navigation
        setTypeValue({clickto})

        //checks to see if user has either dismissed or completed the survey - do not show the modal
        const modalCheck = typeof window !== 'undefined' && window.localStorage.getItem('stickchart:_sc_main_survey');
        
        if((modalCheck === 'true') || (modalCheck === 'dismissed')){
            //here the user has either completed or dismissed the survey
            //set registration modal state to false - do not show
            setModalIsOpen(false)
            
            // ensure scrolling is unlocked 
            if (typeof window !== 'undefined') {
              document.documentElement.style.overflow = 'scroll';
              document.body.scroll = "yes";
            }
            //navigate to the desired page
            navigate(`/${clickto}`)
        }
        else {
            //here the user has not completed or dismissed the survey, so present the modal to the user
            //lock background scrolling
            if (typeof window !== 'undefined') {
              document.documentElement.style.overflow = 'hidden';
              document.body.scroll = "no";
            }
            //set registration modal state to true - show modal
            setModalIsOpen(true)
            
        }
    }

    const setTypeValue =({clickto})=>{
      //sets the type (usertype) state for pass through when modal is completed
      setType(prev => prev + clickto)
    }

    const setModalIsOpenToFalse =()=>{
        //call navigate function to switch to selected page
        navigateToFeedback()
        //close modal
        setModalIsOpen(false)
        //unlock scrolling
        if (typeof window !== 'undefined') {
          document.documentElement.style.overflow = 'scroll';
          document.body.scroll = "yes";
        }
    }

    const navigateToFeedback =()=>{
      //uses type state (usertype) and navigates to selected page
      navigate(`/${type}`)
    }

    return (
        // <section class="max-w-7xl mx-auto my-0 relative py-6 -mt-12 scmd:-mt-40 scxl:-mt-56">
        <section class="max-w-7xl mx-auto my-0 relative">
          <div class="flex flex-wrap w-full content-center">
            <div class="w-full m-auto flex-grow-0 flex-shrink-0 text-gray-600 text-center">
              <div class="grid grid-cols-1 scmd:grid-cols-2 gap-14 px-6 scmd:px-20 sclg:px-24 scxl:px-20">
                  {userTypes.map(userType => {
                      const {id,Image,Heading,subheading,altText,Link:clickto} = userType
                      const btnID = "btn-homeusertype-" + clickto + "-id" 
                      
                      return (
                          <article key={id} class="text-gray-600 bg-gray-50 py-10 px-6 mb-4 rounded-lg shadow-md transition duration-500 ease-in-out hover:shadow-2xl transform hover:-translate-y-1 hover:scale-110">
                              <div class="flex flex-col flex-wrap scmd:flex-nowrap h-auto">
                                <div class="mx-auto pb-4 h-auto w-7/12 scmd:px-5 flex-shrink-0">
                                  {/* Image */}
                                  <Img fluid={Image.childImageSharp.fluid} fadeIn={false} loading="eager" alt={altText}/>
                                </div>
                                <div class="flex-shrink mx-auto h-auto">
                                  {/* Title */}
                                  <h4>{Heading}</h4>
                                </div>
                                <div class="flex-shrink h-auto text-left">
                                  {/* Body */}
                                  <p>
                                    <ReactMarkdown source={subheading} />
                                  </p>
                                </div>
                                <div class="h-auto">
                                  <div class="container flex justify-center content-center pt-6 scmd:pt-1 -mb-4">
                                    {/* Button - passes through the selected usertype (in clickto value) to the functions for proper navigation*/}
                                    <button id={btnID} type="button" onClick={e => setModalIsOpenToTrue({clickto})} class="btn-outline-primary rounded w-44 shadow-md">
                                      <span class="text-lg">{clickto}s</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                          </article>
                      )
                  })}
              </div>
            </div>
          </div>
          <UserTypeModal 
                displayModal={modalIsOpen}
                closeModal={setModalIsOpenToFalse}
                usertype={type}
              />
        </section>
    )
}

export default LandingUserTypes