import React from 'react'
import LandingScroll from './landingscroll'

const LandingScrolls = ({scrolls}) => {
    return (
        <section class="block box-border w-full pt-16 scmd:pt-4">
            {scrolls.map((scroll,index)=>{
                return (
                    <LandingScroll key={scroll.id} index={index} {...scroll} /> // the "...project" passed through all of the results returned for project
                )
            })}
        </section>
    )
}

export default LandingScrolls
