//source of origination - https://developer.linkedin.com/docs/reference/industry-codes
const industries = [
    {
        value: 'agri',
        label: 'Agriculture',
        code: '9001',
    },
    {
        value: 'auto',
        label: 'Automotive',
        code: '9005',
    },
    {
        value: 'aviation',
        label: 'Aviation',
        code: '9085',
    },
    {
        value: 'construction',
        label: 'Construction',
        code: '9089',
    },
    {
        value: 'electronics',
        label: 'Electronics',
        code: '9017',
    },
    {
        value: 'facility',
        label: 'Facility Services',
        code: '9093',
    },
    {
        value: 'foodsvc',
        label: 'Food Service',
        code: '9025',
    },
    {
        value: 'industrial',
        label: 'General Industrial',
        code: '9029',
    },
    {
        value: 'govt',
        label: 'Government',
        code: '9037',
    },
    {
        value: 'healthcare',
        label: 'Healthcare',
        code: '9041',
    },
    {
        value: 'hospitality',
        label: 'Hospitality',
        code: '9045',
    },
        {
        value: 'ithw',
        label: 'IT Hardware',
        code: '9033',
    },
    {
        value: 'metals',
        label: 'Metals & Mining',
        code: '9049',
    },
    {
        value: 'office',
        label: 'Office Supplies & Equip',
        code: '9053',
    },
    {
        value: 'energy',
        label: 'Oil & Energy',
        code: '9057',
    },
    {
        value: 'pharma',
        label: 'Pharmaceuticals',
        code: '9061',
    },
    {
        value: 'realestate',
        label: 'Real Estate',
        code: '9097',
    },
    {
        value: 'retail',
        label: 'Retail',
        code: '9069',
    },
    {
        value: 'software',
        label: 'Software',
        code: '9073',
    },
    {
        value: 'telecom',
        label: 'Telecommunications',
        code: '9077',
    },
    {
        value: 'transportation',
        label: 'Transportation',
        code: '9081',
    },
    {
        value: 'utilities',
        label: 'Utilities',
        code: '9101',
    },
    {
        value: 'other',
        label: 'Other',
        code: '9999',
    },
]

export default industries;