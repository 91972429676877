import React from 'react'

const Title = ({heading}) => {
    return (
        <div class="pt-4 uppercase text-base md:text-lg font-bold text-sc-primary-blue">
            {heading}
        </div>
    )
}

export default Title