//source of origination - https://developer.linkedin.com/docs/reference/industry-codes
const industries = [
    {
        value: 'agri',
        label: 'Agriculture',
        code: '9001',
    },
    {
        value: 'auto',
        label: 'Automotive',
        code: '9005',
    },
    {
        value: 'building',
        label: 'Building Products',
        code: '9009',
    },
    {
        value: 'electrical',
        label: 'Electrical',
        code: '9013',
    },
    {
        value: 'electronics',
        label: 'Electronics',
        code: '9017',
    },
    {
        value: 'fm',
        label: 'Facilities Maintenance',
        code: '9021',
    },
    {
        value: 'foodsvc',
        label: 'Food Service',
        code: '9025',
    },
    {
        value: 'industrial',
        label: 'General Industrial',
        code: '9029',
    },
    {
        value: 'govt',
        label: 'Government',
        code: '9037',
    },
    {
        value: 'healthcare',
        label: 'Healthcare',
        code: '9041',
    },
    {
        value: 'hospitality',
        label: 'Hospitality',
        code: '9045',
    },
    {
        value: 'ithw',
        label: 'IT Hardware',
        code: '9033',
    },
    {
        value: 'metals',
        label: 'Metals',
        code: '9049',
    },
    {
        value: 'office',
        label: 'Office Supplies & Equip',
        code: '9053',
    },
    {
        value: 'energy',
        label: 'Oil & Energy',
        code: '9057',
    },
    {
        value: 'pharma',
        label: 'Pharmaceuticals',
        code: '9061',
    },
    {
        value: 'plumbhvac',
        label: 'Plumbing/HVAC',
        code: '9065',
    },
    {
        value: 'retail',
        label: 'Retail',
        code: '9069',
    },
    {
        value: 'software',
        label: 'Software',
        code: '9073',
    },
    {
        value: 'telecom',
        label: 'Telecommunications',
        code: '9077',
    },
    {
        value: 'transportation',
        label: 'Transportation',
        code: '9081',
    },
    {
        value: 'other',
        label: 'Other',
        code: '9999',
    },
]

export default industries;