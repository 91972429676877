const customerjobs = [
    {
        value: 'buyer',
        label: 'Buyer',
        code: '2010',
    },
    {
        value: 'contractor',
        label: 'Contractor',
        code: '2014',
    },
    {
        value: 'customer',
        label: 'Customer',
        code: '2005',
    },
    {
        value: 'facmgr',
        label: 'Facility Manager',
        code: '2018',
    },
    {
        value: 'itsrcmgr',
        label: 'IT Sourcing Manager',
        code: '2022',
    },
    {
        value: 'branchmgr',
        label: 'Location/Branch Manager',
        code: '2026',
    },
    {
        value: 'maintpro',
        label: 'Maintenance Professional',
        code: '2001',
    },
    {
        value: 'officemgr',
        label: 'Office Manager',
        code: '2030',
    },
    {
        value: 'plantmgr',
        label: 'Plant Manager',
        code: '2034',
    },
    {
        value: 'proprietor',
        label: 'Proprietor',
        code: '2038',
    },
    {
        value: 'propmanager',
        label: 'Property Manager',
        code: '2003',
    },
    {
        value: 'purchasing',
        label: 'Purchasing Manager',
        code: '2004',
    },
    {
        value: 'restmgr',
        label: 'Restaurant Manager',
        code: '2002',
    },
    {
        value: 'other',
        label: 'Other',
        code: '2999',
    },
]

export default customerjobs;