//source of origination: https://developer.linkedin.com/docs/reference/company-size-codes
const size = [
    {
        value: 'self',
        label: 'Self-Employed',
        code: 'A',
    },
    {
        value: 'xs',
        label: '1-10 Employees',
        code: 'B',
    },
    {
        value: 'sm',
        label: '11-50 Employees',
        code: 'C',
    },
    {
        value: 'smmd',
        label: '51-200 Employees',
        code: 'D',
    },
    {
        value: 'md',
        label: '201-500 Employees',
        code: 'E',
    },
    {
        value: 'mdlg',
        label: '501-1000 Employees',
        code: 'F',
    },
    {
        value: 'lg',
        label: '1001-5000 Employees',
        code: 'G',
    },
    {
        value: 'xl',
        label: '5001-10000 Employees',
        code: 'H',
    },
    {
        value: '2xl',
        label: '10001+ Employees',
        code: 'I',
    },
]

export default size;