import React from 'react'
import Image from "gatsby-image"
import ReactMarkdown from "react-markdown"
import Title from '../components/title'


const LandingHero = ({hero}) => {

    const{tagline,heading,body,image, altText} = hero[0]

    return (
        <div class="max-w-7xl mx-auto my-0 pt-28 scmd:pt-32 relative py-0 px-6 scmd:px-1">
            {/* parent container */}
            <div class="flex flex-wrap flex-start">
                {/* left hero column */}
                <div class="w-full scmd:w-7/12  mb-4 flex-grow-0 flex-shrink-0 scmd:pl-5 pr-1 text-gray-600">
                        {/* heading section */}
                        <Title heading={tagline} />
                        <h1 class="mb-6">{heading}</h1>
                        {/* body section */}
                        <div class="hero">
                            <ReactMarkdown source={body} />
                        </div>                        
                </div>
                {/* right hero column */}
                    <div class="w-full h-full scmd:w-5/12  flex-grow-0 scmd:px-5 flex-shrink-0">
                        {/* image section */}
                        <Image fadeIn={false} loading="eager" fluid={image.childImageSharp.fluid} className="w-full scxl:w-full" alt={altText}/>
                    </div>
            </div>
        </div>
    )
}

export default LandingHero
